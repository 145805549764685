import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../../components/blog/Layout'
import MetaData from '../../components/common/MetaData'
import LocalizationContext from '../../components/LocalizationContext'

import { default as styles } from './PageTemplate.module.css'

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const PageTemplate = ({ data, location, pageContext }) => {
    const page = data.ghostPage

    return (
        <LocalizationContext.Provider value={{ locale: pageContext.language }}>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout pathname={location.pathname}>
                <article className="content">
                    <h1 className={styles.contentTitle}>{page.title}</h1>

                    {/* The main page content */}
                    <section
                        className={`${styles.contentBody} load-external-scripts`}
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    />
                </article>
            </Layout>
        </LocalizationContext.Provider>
    )
}

PageTemplate.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            codeinjection_styles: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default PageTemplate

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
